<template>
    <div class="body">
        <div class="list-content">
            <div class="list-content-top">
                <img class="list-content-top-tip" :src="info.tag">
                <!--<img class="list-content-top-xin" src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-2022110505.png">-->
                <!--<img class="list-content-top-banner" :src="info.img1">-->
                <!--banner-->
                <van-swipe class="swipe-wrapper" :autoplay="3000" indicator-color="white" ref="swiper">
                    <van-swipe-item v-for="(item,index) in info.banner" :key="index">
                        <img class="swipe-img list-content-top-banner" :src="item.img" @click="hrefUrlCommon(item.link)">
                        <div class="swipe-title">{{item.title}}</div>
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div  class="list-content-bottom">
                <div class="list-content-bottom-title">
                    <div>{{info.name}}</div>
                </div>
                <div class="list-content-bottom-details" v-if="info.reason">
                    <div class="list-content-bottom-details-1">上榜理由</div>
                    <div>{{info.reason}}</div>
                </div>
                <div class="list-content-type2">
                    <div class="list-content-bottom-details">
                        <div class="list-content-bottom-details-1">推荐指数</div>
                        <div>
                            <van-rate v-model="show_star"
                                      :size="16"
                                      color="#D7B07E"
                                      void-icon="star"
                                      void-color="#eee" readonly
                                      allow-half
                            />
                        </div>
                    </div>
                    <div>
                        <div v-if="info.per_capita">￥人均{{info.per_capita}}</div>
                    </div>
                </div>
                <div class="list-content-type2" v-if="info.open_hours">
                    <div class="list-content-bottom-details">
                        <div class="list-content-bottom-details-1">营业时间</div>
                        <div class="list-content-bottom-details-2">{{info.open_hours}}</div>
                    </div>
                    <!--<div class="list-content-type2-address">-->
                        <!--<img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-2022010502.png"/>-->
                        <!--<div>{{info.address}}</div>-->
                    <!--</div>-->
                </div>
            </div>
        </div>

<!--        <img  @click="showCustomerServicePop" class="subscribe-button" src="https://seetop-1257860468.file.myqcloud.com/html/img/yuyue20221105.png">-->
        <!--  推荐套餐  -->
<!--        <div v-if="productList.length > 0">-->
<!--            <img class="module-title" src="https://seetop-1257860468.file.myqcloud.com/html/img/youhui20221105.png"/>-->
<!--            <div class="module-meal">-->
<!--                <div class="module-meal-box" v-for="item,index in productList" :key="index"  @click="viewProduct(item.product_cell_id)">-->
<!--                    <img class="module-meal-head" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.images[0]"/>-->
<!--                    <div class="module-meal-content">-->
<!--                        <div class="module-meal-content-title">-->
<!--                            <div>{{item.name}}</div>-->
<!--                        </div>-->
<!--                        <div class="module-meal-content-money">-->
<!--                            <div class="module-meal-content-money1">￥{{item.mallprice/100}}</div>-->
<!--                            <div class="module-meal-content-button">立即抢购</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <img v-if="productMore == '1'" @click="getProductList()" class="module-button" src="https://seetop-1257860468.file.myqcloud.com/html/img/more2022110501.png"/>-->
<!--        </div>-->
        <!--  推荐菜品  -->
        <div v-if="info['specialty'].length">
            <img class="module-title" src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/221204%E6%8B%9B%E7%89%8C%E8%8F%9C.jpg"/>
            <div class="zhaopai-number">招牌菜({{info['specialty'].length}})</div>
            <div class="zhaopai-wrapper">
                <div class="zhaopai-item" v-for="item,index in info['specialty']" :key="index">
                    <img class="zhaopai-img" :src="item.url">
                    <div class="zhaopai-name">{{item.name}}</div>
                </div>
            </div>
        </div>
        <!--  商户简介  -->
        <div v-if="info['describe']">
            <img class="module-title" src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/%E6%96%B0%E8%A7%92%E6%A0%87/shjka.png"/>
            <div class="business-des">{{info['describe']}}</div>
        </div>
        <!--<div>
            <img class="module-title" src="https://seetop-1257860468.file.myqcloud.com/html/img/daren20221105.png"/>
            <div class="module-daren">
                <div class="module-daren-box">
                    <div class="module-daren-head"></div>
                    <div class="module-daren-content">
                        <div class="module-daren-content-title">
                            <div>达人推荐|福州缤纷海鲜制造，快来打卡吧</div>
                        </div>
                        <div class="module-daren-content-read">
                            <div class="module-meal-content-read">阅读量66</div>
                            <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon2022110601.png">
                        </div>
                    </div>
                </div>

                <div class="module-daren-box">
                    <div class="module-daren-head"></div>
                    <div class="module-daren-content">
                        <div class="module-daren-content-title">
                            <div>达人推荐|福州缤纷海鲜制造，快来打卡吧</div>
                        </div>
                        <div class="module-daren-content-read">
                            <div class="module-meal-content-read">阅读量66</div>
                            <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon2022110601.png">
                        </div>
                    </div>
                </div>
            </div>
            <img class="module-button" src="https://seetop-1257860468.file.myqcloud.com/html/img/more2022110502.png"/>
        </div>-->

        <div v-if="commentList.length > 0">
            <img class="module-title" src="https://seetop-1257860468.file.myqcloud.com/html/img/pingjia20221105.png"/>
            <div class="store-comment">
                <div class="list-content-matter-label">
                    <div v-for="item,index in info.label" :key="index">{{item}}</div>
                </div>
                <div v-if="info.lastComment.length>0">
                    <div class="store-comment2" v-for="(item,index) in commentList" :key="index">
                        <div class="store-comment-top">
                            <div class="store-comment-user">
                                <img :src="item.headimgurl">
                                <div>{{item.weixinNick ? item.weixinNick : '匿名用户'}}</div>
                            </div>
                            <div class="store-comment-time">{{item.comment_time}}</div>
                        </div>
                        <div class="list-content-matter-star">
                            <div class="list-content-matter-star-img">
                                <van-rate v-model="item.stars"
                                          :size="12"
                                          color="#DDA350"
                                          void-icon="star"
                                          void-color="#ccc" readonly
                                          allow-half
                                />
                            </div>
                            <div class="list-content-matter-star-text">{{item.stars}}分</div>
                        </div>
                        <!--<div class="store-buy-goods">-->
                            <!--<div></div>-->
                            <!--<div>已购套餐:3`4人火锅套餐</div>-->
                        <!--</div>-->
                        <div class="store-comment-content">
                            {{item.comment}}
                        </div>
                    </div>
                </div>
                <div class="store-comment-write" @click="clickComment()">
                    <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-20221110503.png"/>
                    <div>说点什么吧</div>
                </div>
            </div>
            <img v-if="commentMore == '1'" @click="getCommentList()" class="module-button" src="https://seetop-1257860468.file.myqcloud.com/html/img/more2022110503.png"/>
            <!--预约客服弹窗-->
            <customer-service-honor ref="customerServiceHonor"></customer-service-honor>
            <!--评论提示-->
            <comment-alert ref="commentAlert" @toComment="viewCommentList"></comment-alert>
        </div>
    </div>
</template>

<script>
  import { globalConfig } from '../../utils/mixin'
  import { hrefUrlCommon } from '../../utils/tools'
  import CustomerServiceHonor from '../../components/common/CustomerServiceHonor'
  import CommentAlert from '../../components/honor/CommentAlert'

  export default {
    name: 'Comment',
    mixins: [globalConfig],
    components: { CustomerServiceHonor, CommentAlert },
    data () {
      return {
        show_star: 5,
        info: {
          online: [],
          lastComment: []
        },
        productList: [],
          commentList: [],
          productPage: 1,
          commentPage: 1,
          productMore: 1,
          commentMore: 1
      }
    },
    methods: {
        hrefUrlCommon,
      init: function () {
        this.axios.post(this.apiUrl + 'mall/top_rank/store', {
          id: this.$route.params.id
        }).then((response) => {
          console.log(response)
          this.info = response.data.info
        })
      },
      ontoProduct: function (id) {
          window.location.href = 'https://club.seetop.net.cn/#/product/' + id
      },
      // 点击评论
      clickComment: function (id) {
        this.$refs.commentAlert.showPop()
      },
      viewCommentList: function () {
        this.$router.push('/commentList/1?pid=' + this.$route.params.id)
      },
        viewProduct: function (cellid) {
            this.$router.push('/product/' + cellid)
        },
        viewMoreProduct: function () {
            this.$router.push('/home')
        },
        viewMoreComment: function () {
            this.$router.push('/commentList')
        },
        // 展示客服面板
        showCustomerServicePop: function () {
            this.$refs.customerServiceHonor.showPop()
        },
        getProductList: function () {
            const that = this
            this.axios.post(this.apiUrl + 'mall/top_rank/getRankBusinessProductList', {
                businessid: this.$route.params.id,
                page: this.productPage
            }).then((response) => {
                if (response.data.list.length > 0) {
                    that.productPage++
                    that.productList = that.productList.concat(response.data.list)
                    console.log(that.productList)
                } else {
                    that.$toast({
                        message: '没有更多了~'
                    })
                    that.productMore = 0
                }
            })
        },
        getCommentList: function () {
            const that = this
            this.axios.post(this.apiUrl + 'mall/top_rank/getRankCommentList', {
                businessid: this.$route.params.id,
                page: this.commentPage
            }).then((response) => {
                if (response.data.list.length > 0) {
                    that.commentPage++
                    that.commentList = that.commentList.concat(response.data.list)
                    console.log(that.commentList)
                } else {
                    that.$toast({
                        message: '没有更多了~'
                    })
                    that.commentMore = 0
                }
            })
        }
    },
    created () {
      this.init()
        this.getProductList()
        this.getCommentList()
    }
  }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }
    .body {
        width: 100vw;
        padding: 0 0 8vw 0;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/bg2022110606.png") #000;
        background-size: 100vw;
    }
    .list-content{
        padding-bottom: 4vw;
        color: #fff;
    }
    .list-content-top{
        width: 90vw;
        margin: 0 5vw 0 5vw;
        padding: 2rem 0 4vw 0 ;
        position: relative;
    }
    .list-content-top-tip{
        position: absolute;
        margin: 0 0 0 3vw;
        width: 30vw;
        z-index: 9999;
    }
    .list-content-top-xin{
        position: absolute;
        margin: 7vw 0 0 78vw;
        width: 7.2vw;
    }
    .list-content-top-banner{
        width: 100%;
        margin-top: 3vw;
    }
    .list-content-bottom{
        width: 90vw;
        margin: 0 5vw;
    }
    .list-content-bottom-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
    }
    .list-content-bottom-money{
        font-size: 3vw;
    }
    .list-content-bottom-money span{
        font-size: 4.2vw;
    }
    .list-content-type2{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .list-content-type2-address{
        width: 40vw;
        display: flex;
        align-items: center;
        justify-content: right;
    }
    .list-content-type2-address img{
        width: 3.2vw;
        height: 3.6vw;
        margin-right: 2vw;
    }
    .list-content-bottom-details{
        display: flex;
        align-items: center;
        margin: 2.2vw 0;
    }
    .list-content-bottom-details-1{
        padding: 1vw 2vw;
        color: #333;
        font-size: 3vw;
        border-radius: 5vw;
        background: #D7B07E;
        height: 1.8rem;
        margin-right: 3vw;
        white-space:nowrap;
    }
    .list-content-bottom-details-2{
        /*width: 30vw;*/
    }
    .subscribe-button{
        width: 74vw;
        margin: 2vw 13vw;
    }
    .module-title{
        width: 90vw;
        margin: 2vw 5vw;
    }
    .module-button{
        width: 36vw;
        margin: 3vw 32vw 10vw 32vw;
    }
    .list-content-matter-star {
        padding: 1.5vw 4vw 0 4vw;
        display: flex;
        align-items: center;
    }
    .list-content-matter-star-text {
        color: #DDA350;
        font-size: 10px;
        margin-left: 3vw;
        font-weight: 600;
    }
    .store-goods-img img {
        width: 125px;
        margin-right: 10px;
        border-radius: 5px;
    }
    .store-comment {
        width: 90vw;
        margin: 2vw 5vw 3vw;
        padding-bottom: 3vw;
        border-radius: 5px;
        background: #f0f0f0;
    }
    .store-comment2{
        margin: 2vw 0;
    }
    .store-comment-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3vw 4vw 1vw;
    }
    .store-comment-user {
        display: flex;
        align-items: center;
    }
    .store-comment-time {
        font-size: 11px;
        color: #999;
    }
    .store-comment-user img {
        width: 7.2vw;
        height: 7.2vw;
        border-radius: 7.2vw;
    }
    .store-comment-user div {
        margin-left: 2vw;
        font-size: 13px;
        font-weight: 600;
    }
    .store-buy-goods{
        display: flex;
        align-items: center;
        color: #b2b2b2;
        font-size: 3.8vw;
        padding: 1.5vw 4vw;
    }
    .store-buy-goods div:nth-of-type(1){
        height: 3.5vw;
        width: 3px;
        background: #D1D0D0;
        margin: 0 1.5vw;
    }
    .store-comment-content {
        color: #333;
        font-size: 13px;
        padding: 1vw 4vw;
        font-weight: 400;
    }
    .store-comment-write{
        width: 82vw;
        display: flex;
        align-items: center;
        height: 12vw;
        border-radius: 12vw;
        color: #fff;
        font-size: 4.6vw;
        background: #D1D0D0;
        margin: 7vw auto 3vw;
    }
    .store-comment-write img{
        width: 6vw;
        height: 6vw;
        margin: 0 4vw 0 7.2vw;
    }

    .module-meal{
        width: 100vw;
        padding: 2vw 5vw;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .module-meal-box{
        width: 42vw;
        margin-bottom: 4vw;
    }
    .module-meal-head{
        width: 44vw;
        min-height: 30vw;
        border-radius: 2vw 2vw 0 0;
        /*background: #D7B07E;*/
    }
    .module-meal-content{
        width: 44vw;
        min-height: 21vw;
        border-radius: 0 0 2vw 2vw;
        background: #fff;
        margin-top: -2vw;
    }
    .module-meal-content-title{
        color: #333;
        font-weight: 600;
        padding: 2.5vw 3vw 1.8vw 3vw;
        font-size: 13px;
    }
    .module-meal-content-money{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1vw 3vw 2.5vw 3vw;
    }
    .module-meal-content-money1{
        font-size: 4.2vw;
        font-weight: 600;
        color: #333;
    }
    .module-meal-content-money1 del{
        font-size: 3.2vw;
        font-weight: 500;
        color: #b2b2b2;
    }
    .module-meal-content-button{
        color: #333;
        background: #D7B07E;
        font-size: 3.4vw;
        padding: 1.8vw 3vw;
        border-radius: 8vw;
    }

    .module-daren{
        width: 100vw;
        padding: 2vw 5vw;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .module-daren-box{
        width: 42vw;
        margin-bottom: 4vw;
    }
    .module-daren-head{
        width: 44vw;
        min-height: 50vw;
        border-radius: 2vw 2vw 0 0;
        background: #D7B07E;
    }
    .module-daren-content{
        width: 44vw;
        min-height: 21vw;
        border-radius: 0 0 2vw 2vw;
        background: #fff;
    }
    .module-daren-content-title{
        font-size: 3.8vw;
        color: #333;
        font-weight: 600;
        padding: 2.5vw 3vw 1.8vw 3vw;
    }
    .module-daren-content-read{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:0.5vw 3vw 2.5vw 3vw;
    }
    .module-daren-content-read{
        font-size: 3.6vw;
        font-weight: 600;
        color: #b2b2b2;
    }
    .module-daren-content-read img{
        width: 4vw;
        height: 4vw;
    }

    .list-content-matter-label {
        display: flex;
        flex-wrap: wrap;
        padding: 3vw 4vw 1vw 4vw;
    }
    .list-content-matter-label div {
        height: 28px;
        line-height: 28px;
        color: #333;
        background: #D7B07E;
        border-radius: 28px;
        padding: 0 4vw;
        margin: 2vw 3vw 0 0;
        font-size: 13px;
        font-weight: 400;
    }
    .swipe-title{
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 18px;
        position: absolute;
        margin-top: -70px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        background-color: #848484a6;
    }
    .van-swipe__indicator{
        width: 8px;
        height: 8px;
        border-radius: 8px;
    }
    .business-des {
        width: 90%;
        margin: 1rem auto 3rem;
        color: #D7B07E;
    }
    .zhaopai-number {
        font-size: 0.9rem;
        color: #D7B07E;
        margin: 0 0 0.5rem 1.5rem;
        background-color: #282828;
        display: inline-block;
        padding: 0 0.5rem;
        border-radius: 0.3rem;
    }
    .zhaopai-wrapper {
        display: flex;
        flex-flow: row;
        overflow: scroll;
        padding: 0 0 2rem 0;
    }
    .zhaopai-item {
        min-width: 10rem;
        margin-left: 1.4rem;
    }
    .zhaopai-img {
        width: 100%;
    }
    .zhaopai-name {
        width: 100%;
        font-size: 0.9rem;
        color: #D7B07E;
        text-align: center;
        margin-top: 0.3rem;
    }
</style>
